// import ApiResponse from 'sr/models/ApiResponse'
import {get, remove} from 'sr/utils/axios/index'
import {toast} from 'react-toastify'

export interface TxnHistory {
  amount: number
  transactionNo: string
  transactionMsg: string
  transactionType: string
  transactionMode: string
  userId: string
  orderId: string
  status: string
  createdAt: string
  updatedAt: string
  transactionDetails: any
  id: string
}

export interface TxnApiResponse {
  results: TxnHistory[]
  page: number
  limit: number
  totalPages: number
  totalResults: number
}
interface TxnFilters {
  limit?: number
  page?: number

  amount?: number
  transactionNo?: string
  transactionType?: string
  userId?: string
  orderId?: string
  status?: string
}

const filterPayload = (payload: TxnFilters) => {
  return Object.fromEntries(
    Object.entries(payload).filter(([_, value]) => value !== undefined && value !== null)
  )
}

export const fetchTransactions = async (payload: TxnFilters) => {
  try {
    const filteredPayload = filterPayload(payload)
    const res = await get<TxnApiResponse>('/transaction-history', filteredPayload)
    if (res.results && res.results.length > 0) {
      return res
    } else {
      throw new Error('No data found')
    }
  } catch (e: any) {
    toast.error(e.message)
  }
}

export const deleteTransaction = async (id: string) => {
  try {
    const res = await remove<any>(`/transaction-history/${id}`, {})
    return res
  } catch (e: any) {
    toast.error(e.message)
    return []
  }
}
